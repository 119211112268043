var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "测试",
          "left-text": "",
          "right-text": "",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft },
      }),
      _c("van-button", { on: { click: _vm.goMini } }, [_vm._v("跳转小程序")]),
      _c(
        "wx-open-launch-weapp",
        {
          attrs: {
            id: "launch-btn",
            username: "gh_4e6c969491a9",
            path: "/pages/index/index",
          },
          on: { launch: _vm.handleLaunchFn, error: _vm.handleErrorFn },
        },
        [
          [
            _c(
              "button",
              {
                staticStyle: {
                  width: "200px",
                  height: "45px",
                  "text-align": "center",
                  "font-size": "17px",
                  display: "block",
                  margin: "0 auto",
                  padding: "8px 24px",
                  border: "none",
                  "border-radius": "4px",
                  "background-color": "#07c160",
                  color: "#fff",
                },
              },
              [_vm._v(" 打开小程序 ")]
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }