import _defineProperty from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\defineProperty.js";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/goods-action/style";
import _GoodsAction from "vant/es/goods-action";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { userInfo } from '@/api/user';
export default {
  name: 'Home',
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _Card.name, _Card), _defineProperty(_components, _Tag.name, _Tag), _defineProperty(_components, _Toast.name, _Toast), _defineProperty(_components, _Swipe.name, _Swipe), _defineProperty(_components, _SwipeItem.name, _SwipeItem), _defineProperty(_components, _GoodsAction.name, _GoodsAction), _defineProperty(_components, _Icon.name, _Icon), _defineProperty(_components, _RadioGroup.name, _RadioGroup), _defineProperty(_components, _Radio.name, _Radio), _components),
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {// this.getShopWxConfig()
  },
  methods: {
    handleLaunchFn: function handleLaunchFn(e) {
      console.log('success成功', e);
    },
    handleErrorFn: function handleErrorFn(e) {
      console.log('fail这里吗', e.detail);
    },
    goMini: function goMini() {
      location.href = 'weixin://dl/business/?t=4sMUnubdrmq&a=1';
    },
    getShopWxConfig: function getShopWxConfig() {
      var url = window.location.href;
      userInfo(url).then(function (res) {
        var wx1 = window['wx'];
        wx1.config({
          debug: true,
          // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxda711ece6bd54d98',
          // 必填，公众号的唯一标识
          timestamp: '1649765',
          // 必填，生成签名的时间戳
          nonceStr: 'test123',
          // 必填，生成签名的随机串
          signature: '20b83f50403e9755051caacd09d0213d97dec03a',
          // 必填，签名
          jsApiList: [],
          // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-weapp']
        });
        wx1.ready(function () {// console.log('res44444444')
        });
        wx1.error(function (err) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          console.log('err44444444', err);
        });
      });
    },
    // 返回
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};